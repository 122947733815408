export function number(num) {
	switch (true) {
		// convert to K for number from > 1000 < 1 million
		case (num > 999 && num < 1000000):
			return `${(num / 1000).toFixed(1)}K`;
		// convert to M for number from > 1 million
		case num > 1000000:
			return `${(num / 1000000).toFixed(1)}M`;
		default:
			return num.toFixed(0);
	}
}

export function capitalizeFirstLetter(string) {
	if (!string) {
		return string;
	}
	return string.charAt(0).toUpperCase() + string.slice(1);
}