import React, {useEffect, useState} from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import {Badge, Button, Card, Col, Container, FormControl, Image, Row} from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {capitalizeFirstLetter, number} from "../utils/format";
import {shuffle} from "../utils/array";
import SignUp from "../components/sign-up-modal";

const InfluencersPage = () => {
	const search = typeof window !== 'undefined' ? window.location.search : '';
	const urlParams = new URLSearchParams(search);
	const showModal = !!urlParams.get('modal');
	const keyword = urlParams.get('keyword');

	const data = useStaticQuery(graphql`
		query InfluencersQuery {
			influencers: allAirtable(
				limit: 50,
		    filter: {
		      table: { eq: "Beauty" }
		    }
		  ) {
	      nodes {
	        data {
	          Username
	          Full_Name
	          Followers
	          Profile_Pic_Link
	          Category
	          Engagement_Rate
	        }
	      }
		  }
		}
  `)

	const [influencers, setInfluencers] = useState([]);
	useEffect(() => {
		setInfluencers(shuffle(data.influencers.nodes));
	}, [])

	return (
		<Layout>
			<SEO title="Find the right influencers to promote your brand"/>
			<Container>
				<h2 className="pt-3">Influencers ready to promote <b className="text-primary">your brand</b></h2>
				<p>Find the right influencer for your promotional campaign. Contact the influencers directly or request a free quote for your campaign.</p>
				<Row>
					<Col md="6">
						<form action="/influencers" method="GET" className="d-flex mb-3">
							<FormControl
								id="keyword"
								name="keyword"
								placeholder='Search by keyword, category, location and more'
								aria-label='Search by keyword, category, location and more'
								aria-describedby="find-influencers"
								className="mr-3"
							/>
							<Button type="submit" variant="primary">Search</Button>
						</form>
					</Col>
				</Row>
				<Row>
					<Col>
						{influencers.map(influencer => {
							const { Username, Profile_Pic_Link, Engagement_Rate, Full_Name, Followers, Category } = influencer.data;
							const isBig = Followers > 5000;
							const price = Followers / 100;
							const rating = (3 + (Math.random() + 1)).toFixed(1);
							const steps = Username.length < 3 ? 1 : Username.length / 3;
							const maskedUsername = `${Username.slice(0, steps)}***${Username.slice(-steps)}`;
							return (
								<Card body border="light" className="mb-4 shadow">
									<Row className="align-items-center">
										<Col className="d-flex align-items-center" md="6">
											<Image
												className="mr-3 border"
												src={Profile_Pic_Link}
												roundedCircle
												style={{ width: '80px', height: '80px' }}
											/>
											<div className="flex-grow-1">
												<h5 className="mb-1">{Full_Name}</h5>
												<Link to='?modal=true'>@{maskedUsername}</Link>
												<div>
													{keyword && (
														<Badge pill variant="secondary mr-1">{capitalizeFirstLetter(keyword)}</Badge>
													)}
													<Badge pill variant="secondary">{Category}</Badge>
												</div>
											</div>
										</Col>
										<Col className="d-flex justify-content-center mt-3 mt-md-0" md="4">
											<div className="text-center mr-5">
												<h6 className="text-muted">Followers</h6>
												<span className="font-weight-bolder">{number(Followers)}</span>
											</div>
											<div className="text-center mr-5">
												<h6 className="text-muted">Engagement</h6>
												<span className="font-weight-bolder">{Engagement_Rate}%</span>
											</div>
											{isBig ? (
												<div className="d-flex text-center justify-content-center align-items-around flex-column">
													<div>>$100</div>
													<small>per post</small>
												</div>
											) : (
												<div className="d-flex text-center justify-content-center align-items-around flex-column">
													<div>${price.toFixed(2)}</div>
													<small>per post</small>
												</div>
											)}
										</Col>
										<Col md="2" className="mt-3 mt-md-0">
											<div className="text-center mb-1"><span className="text-warning">★★★★{rating > 4.4 ? '★' : '☆'}</span> <span className="text-muted">{rating}</span></div>
											<div className="text-center">
												<Button as={Link} to='?modal=true' className="w-100">View Profile</Button>
												<div>
													{Category === 'Lifestyle' ? (
														<>
															<span className="text-danger font-weight-bold">&bull;</span>&nbsp;<small>Replies within 2 hours</small>
														</>
													) : (
														<>
															<span className="text-success font-weight-bold">&bull;</span>&nbsp;<small>Available Now</small>
														</>
													)}
												</div>
											</div>
										</Col>
									</Row>
								</Card>
							)
						})}
					</Col>
				</Row>
			</Container>
			<SignUp show={showModal} />
		</Layout>
	)
}

export default InfluencersPage
