import React, {useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import buzzvertLogo from '../images/b2_logo_white.png';

const SignUp = ({ show }) => {
	const [loading, setLoading] = useState(false);
	const handleResponse = (e) => {
		e.preventDefault();
		const form = e.currentTarget;
		setLoading(true);
		fetch(form.action, {
			method: 'POST',
			body: new FormData(form),
		}).then(() => {
			window.location.href = '/thanks';
		});
	};
	return (
		<Modal show={show} centered>
			<Form onSubmit={handleResponse} action="https://getform.io/f/1eff9451-d79f-47af-b63e-b85d05fe9736" method="POST">
				<Modal.Body>
					<h3>Create free <b className="text-primary">Buzzvert</b> account</h3>
					<p className="text-muted">👋 You’re almost there. Please fill in the form to create a Buzzvert account and start promoting your brand.</p>
					<Form.Group controlId="formName">
						<Form.Label>Your name</Form.Label>
						<Form.Control type="text" required name="name" placeholder="Full name"/>
					</Form.Group>
					<Form.Group controlId="formEmail">
						<Form.Label>Your email address</Form.Label>
						<Form.Control type="email" required name="email" placeholder="Email" />
						<Form.Text className="text-muted">You will receive further instructions on this email address</Form.Text>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer className="justify-content-center">
					<Button className="w-100" disabled={loading} variant="success" type="submit">{loading ? (
						<>
							<span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"/>
							Loading...
						</>
					) : 'Sign Up'}</Button>
					<small className="text-muted">
						By clicking <b>Sign Up</b> you agree to
						our Terms of Use and Privacy Policy.
					</small>
				</Modal.Footer>
			</Form>
		</Modal>
	);
}

export default SignUp;